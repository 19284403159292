var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        [
          _c(
            "b-tabs",
            { attrs: { pills: "" } },
            [
              _c("b-tab", {
                attrs: { title: "포인트 사용/만료" },
                on: {
                  click: function ($event) {
                    _vm.viewType = "summary"
                  },
                },
              }),
              _c("b-tab", {
                attrs: { title: "포인트 적립 현황" },
                on: {
                  click: function ($event) {
                    _vm.viewType = "earning"
                  },
                },
              }),
              _c("b-tab", {
                attrs: { title: "포인트 최대 적립금액" },
                on: {
                  click: function ($event) {
                    _vm.viewType = "earning-max"
                  },
                },
              }),
              _c("b-tab", {
                attrs: { title: "포인트 교환 현황" },
                on: {
                  click: function ($event) {
                    _vm.viewType = "exchange"
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card",
        [
          _vm.viewType === "summary"
            ? _c("summary-view")
            : _vm.viewType === "earning"
            ? _c("earning-view")
            : _vm.viewType === "earning-max"
            ? _c("earning-max-view")
            : _vm.viewType === "exchange"
            ? _c("exchange-view")
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }