var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-table-simple",
    {
      staticStyle: { "max-height": "65vh" },
      attrs: { bordered: "", responsive: "", "sticky-header": "" },
    },
    [
      _c("colgroup", [
        _c("col", { staticStyle: { width: "140px" } }),
        _c("col", { staticStyle: { width: "110px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "130px" } }),
        _c("col", { staticStyle: { width: "160px" } }),
        _c("col", { staticStyle: { width: "110px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "130px" } }),
        _c("col", { staticStyle: { width: "160px" } }),
        _c("col", { staticStyle: { width: "110px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "130px" } }),
        _c("col", { staticStyle: { width: "160px" } }),
        _c("col", { staticStyle: { width: "110px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "130px" } }),
        _c("col", { staticStyle: { width: "160px" } }),
        _c("col", { staticStyle: { width: "110px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "160px" } }),
        _c("col", { staticStyle: { width: "110px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "160px" } }),
        _c("col", { staticStyle: { width: "110px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "160px" } }),
        _c("col", { staticStyle: { width: "110px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "130px" } }),
        _c("col", { staticStyle: { width: "160px" } }),
      ]),
      _c(
        "b-thead",
        { attrs: { "head-variant": "light" } },
        [
          _c(
            "b-tr",
            [
              _c(
                "b-th",
                {
                  staticClass: "sticky-header",
                  staticStyle: { left: "0" },
                  attrs: { rowspan: "2" },
                },
                [_vm._v(" 구분 ")]
              ),
              _c("b-th", { attrs: { colspan: "4" } }, [
                _vm._v(" 설문조사(자체-닥터인포) "),
              ]),
              _c("b-th", { attrs: { colspan: "4" } }, [
                _vm._v(" 설문조사(자체-MCM) "),
              ]),
              _c("b-th", { attrs: { colspan: "4" } }, [
                _vm._v(" 설문조사(제약사) "),
              ]),
              _c("b-th", { attrs: { colspan: "4" } }, [_vm._v(" 이벤트 ")]),
              _c("b-th", { attrs: { colspan: "3" } }, [
                _vm._v(" (월별미션) 댓글 작성 "),
              ]),
              _c("b-th", { attrs: { colspan: "3" } }, [
                _vm._v(" (월별미션) MY 세미나 등록 "),
              ]),
              _c("b-th", { attrs: { colspan: "3" } }, [
                _vm._v(" (월별미션) Weekly Choice "),
              ]),
              _c("b-th", { attrs: { colspan: "4" } }, [_vm._v(" 전체 ")]),
            ],
            1
          ),
          _c(
            "b-tr",
            [
              _c("b-th", { staticStyle: { top: "38.55px" } }, [
                _vm._v(" 적립 건수 "),
              ]),
              _c("b-th", { staticStyle: { top: "38.55px" } }, [
                _vm._v(" 적립 회원수 "),
              ]),
              _c("b-th", { staticStyle: { top: "38.55px" } }, [
                _vm._v(" 적립 비회원수 "),
              ]),
              _c("b-th", { staticStyle: { top: "38.55px" } }, [
                _vm._v(" 적립 금액 "),
              ]),
              _c("b-th", { staticStyle: { top: "38.55px" } }, [
                _vm._v(" 적립 건수 "),
              ]),
              _c("b-th", { staticStyle: { top: "38.55px" } }, [
                _vm._v(" 적립 회원수 "),
              ]),
              _c("b-th", { staticStyle: { top: "38.55px" } }, [
                _vm._v(" 적립 비회원수 "),
              ]),
              _c("b-th", { staticStyle: { top: "38.55px" } }, [
                _vm._v(" 적립 금액 "),
              ]),
              _c("b-th", { staticStyle: { top: "38.55px" } }, [
                _vm._v(" 적립 건수 "),
              ]),
              _c("b-th", { staticStyle: { top: "38.55px" } }, [
                _vm._v(" 적립 회원수 "),
              ]),
              _c("b-th", { staticStyle: { top: "38.55px" } }, [
                _vm._v(" 적립 비회원수 "),
              ]),
              _c("b-th", { staticStyle: { top: "38.55px" } }, [
                _vm._v(" 적립 금액 "),
              ]),
              _c("b-th", { staticStyle: { top: "38.55px" } }, [
                _vm._v(" 적립 건수 "),
              ]),
              _c("b-th", { staticStyle: { top: "38.55px" } }, [
                _vm._v(" 적립 회원수 "),
              ]),
              _c("b-th", { staticStyle: { top: "38.55px" } }, [
                _vm._v(" 적립 비회원수 "),
              ]),
              _c("b-th", { staticStyle: { top: "38.55px" } }, [
                _vm._v(" 적립 금액 "),
              ]),
              _c("b-th", { staticStyle: { top: "38.55px" } }, [
                _vm._v(" 적립 건수 "),
              ]),
              _c("b-th", { staticStyle: { top: "38.55px" } }, [
                _vm._v(" 적립 회원수 "),
              ]),
              _c("b-th", { staticStyle: { top: "38.55px" } }, [
                _vm._v(" 적립 금액 "),
              ]),
              _c("b-th", { staticStyle: { top: "38.55px" } }, [
                _vm._v(" 적립 건수 "),
              ]),
              _c("b-th", { staticStyle: { top: "38.55px" } }, [
                _vm._v(" 적립 회원수 "),
              ]),
              _c("b-th", { staticStyle: { top: "38.55px" } }, [
                _vm._v(" 적립 금액 "),
              ]),
              _c("b-th", { staticStyle: { top: "38.55px" } }, [
                _vm._v(" 적립 건수 "),
              ]),
              _c("b-th", { staticStyle: { top: "38.55px" } }, [
                _vm._v(" 적립 회원수 "),
              ]),
              _c("b-th", { staticStyle: { top: "38.55px" } }, [
                _vm._v(" 적립 금액 "),
              ]),
              _c("b-th", { staticStyle: { top: "38.55px" } }, [
                _vm._v(" 적립 건수 "),
              ]),
              _c("b-th", { staticStyle: { top: "38.55px" } }, [
                _vm._v(" 적립 회원수 "),
              ]),
              _c("b-th", { staticStyle: { top: "38.55px" } }, [
                _vm._v(" 적립 비회원수 "),
              ]),
              _c("b-th", { staticStyle: { top: "38.55px" } }, [
                _vm._v(" 적립 금액 "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.data.length > 0
        ? _c(
            "b-tbody",
            _vm._l(_vm.data, function (m, idx) {
              return _c(
                "b-tr",
                {
                  key: "tr-" + idx,
                  class: { "table-active": idx === _vm.data.length - 1 },
                },
                [
                  _c(
                    "b-td",
                    { staticClass: "sticky-row", staticStyle: { left: "0" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.data.length === 1 && m.isPrefixSum
                            ? "누적 수"
                            : m.isPrefixSum
                            ? "합계"
                            : m.date
                        )
                      ),
                    ]
                  ),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(_vm._f("$numberFormatter")(m.selfSurveyTotalCount))
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.selfSurveyGroupMember)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.selfSurveyGroupNonMember)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.selfSurveyTotalAmount)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.selfMcmSurveyTotalCount)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.selfMcmSurveyGroupMember)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          m.selfMcmSurveyGroupNonMember
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.selfMcmSurveyTotalAmount)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.clientSurveyTotalCount)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.clientSurveyGroupMember)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.clientSurveyGroupNonMember)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.clientSurveyTotalAmount)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(_vm._f("$numberFormatter")(m.eventTotalCount))
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(_vm._f("$numberFormatter")(m.eventGroupMember))
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(_vm._f("$numberFormatter")(m.eventGroupNonMember))
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(_vm._f("$numberFormatter")(m.eventTotalAmount))
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          m.medicalTrendCommentTotalCount
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          m.medicalTrendCommentGroupCount
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          m.medicalTrendCommentTotalPoint
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(_vm._f("$numberFormatter")(m.mySeminarTotalCount))
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(_vm._f("$numberFormatter")(m.mySeminarGroupCount))
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(_vm._f("$numberFormatter")(m.mySeminarTotalPoint))
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.weeklyChoiceTotalCount)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.weeklyChoiceGroupCount)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.weeklyChoiceTotalPoint)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(_vm._s(_vm._f("$numberFormatter")(m.allTotalCount))),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(_vm._f("$numberFormatter")(m.allGroupMember))
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(_vm._f("$numberFormatter")(m.allGroupNonMember))
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(_vm._f("$numberFormatter")(m.allTotalAmount))
                    ),
                  ]),
                ],
                1
              )
            }),
            1
          )
        : _c(
            "b-tbody",
            [
              _c(
                "b-tr",
                [
                  _c("b-td", { attrs: { colspan: "13" } }, [
                    _c(
                      "div",
                      { attrs: { role: "alert", "aria-live": "polite" } },
                      [
                        _c("div", { staticClass: "text-center my-2" }, [
                          _vm._v(" 일치하는 데이터가 없습니다. "),
                        ]),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }