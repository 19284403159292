<template>
  <div>
    <b-row>
      <b-col
        md="8"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <p class="h5 mt-50 mr-75 ml-50">
          일별/누적
        </p>
        <b-form-radio-group
          v-model="query.isPrefixSum"
          @change="changePrefixSum"
        >
          <b-form-radio :value="false">
            일별 (기간:최대30일)
          </b-form-radio>
          <b-form-radio :value="true">
            누적 (기간:최대1년)
          </b-form-radio>
        </b-form-radio-group>
        <b-form-datepicker
          v-model="query.startDate"
          class="el-def"
          placeholder="조회기간(Fr)"
          style="width: 240px"
        />
        <label class="ml-50 mr-50">~</label>
        <b-form-datepicker
          v-model="query.endDate"
          class="el-def"
          placeholder="조회기간(To)"
          style="width: 240px"
        />
      </b-col>
      <b-col
        md="4"
        class="d-flex align-items-center justify-content-end"
      >
        <b-button
          variant="primary"
          class="btn-search"
          @click.prevent="fetchData()"
        >
          조회
        </b-button>
        <b-button
          variant="outline-dark"
          class="btn-search ml-1"
          @click.prevent="excelDownload()"
        >
          <feather-icon
            icon="DownloadIcon"
            class="mr-50"
          />
          <span>엑셀 다운로드</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col
        cols="12"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <b-table-simple
          bordered
          responsive
          sticky-header
          style="max-height: 65vh;"
        >
          <colgroup>
            <col style="width: 140px">
            <template
              v-for="(p, idx) in products"
            >
              <col
                :key="`col1-${idx}`"
                style="width: 120px"
              >
              <col
                :key="`col2-${idx}`"
                style="width: 130px"
              >
              <col
                :key="`col3-${idx}`"
                style="width: 150px"
              >
              <col
                :key="`col4-${idx}`"
                style="width: 120px"
              >
              <col
                :key="`col5-${idx}`"
                style="width: 130px"
              >
              <col
                :key="`col6-${idx}`"
                style="width: 150px"
              >
            </template>
            <!-- 전체 -->
            <col style="width: 120px">
            <col style="width: 130px">
            <col style="width: 160px">
            <col style="width: 120px">
            <col style="width: 130px">
            <col style="width: 160px">
          </colgroup>
          <b-thead head-variant="light">
            <b-tr>
              <b-th
                rowspan="3"
                class="sticky-header"
                style="left: 0"
              >
                구분
              </b-th>
              <b-th
                v-for="(p, idx) in products"
                :key="`th1-${idx}`"
                colspan="6"
              >
                {{ p }}
              </b-th>
              <b-th colspan="6">
                전체
              </b-th>
            </b-tr>
            <b-tr>
              <template
                v-for="(p, idx) in products"
              >
                <b-th
                  :key="`th1-${idx}`"
                  colspan="3"
                >
                  회원
                </b-th>
                <b-th
                  :key="`th2-${idx}`"
                  colspan="3"
                >
                  비회원
                </b-th>
              </template>
              <!-- 전체 -->
              <b-th colspan="3">
                회원
              </b-th>
              <b-th colspan="3">
                비회원
              </b-th>
            </b-tr>
            <b-tr>
              <template
                v-for="(p, idx) in products"
              >
                <b-th
                  :key="`rh1-${idx}`"
                  style="top: 38.55px;"
                >
                  교환 건수
                </b-th>
                <b-th
                  :key="`rh2-${idx}`"
                  style="top: 38.55px;"
                >
                  교환 회원수
                </b-th>
                <b-th
                  :key="`rh3-${idx}`"
                  style="top: 38.55px;"
                >
                  교환 금액
                </b-th>
                <b-th
                  :key="`rh4-${idx}`"
                  style="top: 38.55px;"
                >
                  교환 건수
                </b-th>
                <b-th
                  :key="`rh5-${idx}`"
                  style="top: 38.55px;"
                >
                  교환 비회원수
                </b-th>
                <b-th
                  :key="`rh6-${idx}`"
                  style="top: 38.55px;"
                >
                  교환 금액
                </b-th>
              </template>
              <!-- 전체 -->
              <b-th style="top: 38.55px;">
                교환 건수
              </b-th>
              <b-th style="top: 38.55px;">
                교환 회원수
              </b-th>
              <b-th style="top: 38.55px;">
                교환 금액
              </b-th>
              <b-th style="top: 38.55px;">
                교환 건수
              </b-th>
              <b-th style="top: 38.55px;">
                교환 비회원수
              </b-th>
              <b-th style="top: 38.55px;">
                교환 금액
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody v-if="data.length > 0">
            <b-tr
              v-for="(m, idx) in data"
              :key="`tr1-${idx}`"
              :class="{ 'table-active': idx === data.length - 1 }"
            >
              <b-td
                class="sticky-row"
                style="left: 0"
              >{{ (data.length === 1 && m.isPrefixSum) ? '누적 수' : (m.isPrefixSum ? "합계" : m.date) }}</b-td>
              <template
                v-for="(p, pIdx) in m.products"
              >
                <b-td :key="`tr2-${pIdx}`">
                  {{ p.exchangeTotalCountMember | $numberFormatter }}
                </b-td>
                <b-td :key="`tr3-${pIdx}`">
                  {{ p.exchangeGroupCountMember | $numberFormatter }}
                </b-td>
                <b-td :key="`tr4-${pIdx}`">
                  {{ p.exchangeTotalAmountMember | $numberFormatter }}
                </b-td>
                <b-td :key="`tr5-${pIdx}`">
                  {{ p.exchangeTotalCountNonMember | $numberFormatter }}
                </b-td>
                <b-td :key="`tr6-${pIdx}`">
                  {{ p.exchangeGroupCountNonMember | $numberFormatter }}
                </b-td>
                <b-td :key="`tr7-${pIdx}`">
                  {{ p.exchangeTotalAmountNonMember | $numberFormatter }}
                </b-td>
              </template>
              <b-td>{{ m.exchangeTotalCountMember | $numberFormatter }}</b-td>
              <b-td>{{ m.exchangeGroupCountMember | $numberFormatter }}</b-td>
              <b-td>{{ m.exchangeTotalAmountMember | $numberFormatter }}</b-td>
              <b-td>{{ m.exchangeTotalCountNonMember | $numberFormatter }}</b-td>
              <b-td>{{ m.exchangeGroupCountNonMember | $numberFormatter }}</b-td>
              <b-td>{{ m.exchangeTotalAmountNonMember | $numberFormatter }}</b-td>
            </b-tr>
          </b-tbody>

          <b-tbody v-else>
            <b-tr>
              <b-td colspan="7">
                <div
                  role="alert"
                  aria-live="polite"
                >
                  <div class="text-center my-2">
                    일치하는 데이터가 없습니다.
                  </div>
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import axios from '@axios'
import {
  BTableSimple, BThead, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import { incrementAlphabet } from '@core/utils/utils'
import { onMounted, ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ExcelJS from "exceljs"
import { saveAs } from "file-saver"

export default {
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  setup() {
    const data = ref([])
    const products = ref([])

    onMounted(() => {
      fetchData()
    })

    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon

      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }

      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    const changePrefixSum = () => {
      if (query.value.isPrefixSum) {
        query.value.startDate = dayjs().add(-1, 'month').format('YYYY-MM-DD')
      } else {
        query.value.startDate = dayjs().add(-6, 'day').format('YYYY-MM-DD')
      }

      query.value.endDate = dayjs().format('YYYY-MM-DD')
    }

    const query = ref({
      startDate: dayjs().add(-6, 'day').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
      isPrefixSum: false,
    })

    const fetchData = () => {
      const frDate = dayjs(query.value.startDate)
      const toDate = dayjs(query.value.endDate)

      if (query.value.isPrefixSum && Math.abs(frDate.diff(toDate, 'day')) > 365) {
        pushToast('warning', '조회 기간은 최대 1년을 넘을 수 없습니다.')
        return
      }
      if (query.value.isPrefixSum === false && Math.abs(frDate.diff(toDate, 'day')) > 30) {
        pushToast('warning', '조회 기간은 최대 30일을 넘을 수 없습니다.')
        return
      }

      axios.get('/fa/stats-user-point/gift-exchange', {
        params: {
          startDate: query.value.startDate,
          endDate: query.value.endDate,
          isPrefixSum: query.value.isPrefixSum,
        },
      })
        .then(rs => {
          const result = rs.data

          data.value = result
          if (result && result.length) { products.value = result[0].products.map(x => x.goodsName) } else { products.value = [] }
        })
        .catch(() => {
          pushToast('danger', '데이터 조회 중 오류가 발생하였습니다.')
        })
    }

    const excelDownload = async () => {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet("sheet1")

      worksheet.addRow()

      worksheet.getCell('A1').value = '구분'
      worksheet.mergeCells(`A1:A3`)

      let columnAlphabet = 'B'
      for (let i = 0; i < products.value.length; i += 1) {
        const brandCol = columnAlphabet
        // 브랜드명
        worksheet.getCell(`${brandCol}1`).value = products.value[i]
        worksheet.getCell(`${brandCol}2`).value = '회원'
        worksheet.getCell(`${brandCol}3`).value = '교환 건수'
        columnAlphabet = incrementAlphabet(columnAlphabet)
        worksheet.getCell(`${columnAlphabet}3`).value = '교환 회원수'
        columnAlphabet = incrementAlphabet(columnAlphabet)
        worksheet.getCell(`${columnAlphabet}3`).value = '교환 금액'
        // 회원 열 병합
        worksheet.mergeCells(`${brandCol}2:${columnAlphabet}2`)

        columnAlphabet = incrementAlphabet(columnAlphabet)

        const nonMemberCol = columnAlphabet
        worksheet.getCell(`${columnAlphabet}2`).value = '비회원'
        worksheet.getCell(`${columnAlphabet}3`).value = '교환 건수'
        columnAlphabet = incrementAlphabet(columnAlphabet)
        worksheet.getCell(`${columnAlphabet}3`).value = '교환 비회원수'
        columnAlphabet = incrementAlphabet(columnAlphabet)
        worksheet.getCell(`${columnAlphabet}3`).value = '교환 금액'
        // 비회원 열 병합
        worksheet.mergeCells(`${nonMemberCol}2:${columnAlphabet}2`)

        // 브랜드명 병합
        worksheet.mergeCells(`${brandCol}1:${columnAlphabet}1`)

        columnAlphabet = incrementAlphabet(columnAlphabet)
      }

      // 전체
      const totalCol = columnAlphabet
      worksheet.getCell(`${totalCol}1`).value = '전체'
      worksheet.getCell(`${totalCol}2`).value = '회원'
      worksheet.getCell(`${totalCol}3`).value = '교환 건수'
      columnAlphabet = incrementAlphabet(columnAlphabet)
      worksheet.getCell(`${columnAlphabet}3`).value = '교환 회원수'
      columnAlphabet = incrementAlphabet(columnAlphabet)
      worksheet.getCell(`${columnAlphabet}3`).value = '교환 금액'
      // 회원 열 병합
      worksheet.mergeCells(`${totalCol}2:${columnAlphabet}2`)

      columnAlphabet = incrementAlphabet(columnAlphabet)

      const nonMemberCol = columnAlphabet
      worksheet.getCell(`${columnAlphabet}2`).value = '비회원'
      worksheet.getCell(`${columnAlphabet}3`).value = '교환 건수'
      columnAlphabet = incrementAlphabet(columnAlphabet)
      worksheet.getCell(`${columnAlphabet}3`).value = '교환 비회원수'
      columnAlphabet = incrementAlphabet(columnAlphabet)
      worksheet.getCell(`${columnAlphabet}3`).value = '교환 금액'
      // 비회원 열 병합
      worksheet.mergeCells(`${nonMemberCol}2:${columnAlphabet}2`)

      worksheet.mergeCells(`${totalCol}1:${columnAlphabet}1`)

      const columns = [
        { key: "date", width: 15 },
      ]
      for (let i = 0; i < products.value.length; i += 1) {
        columns.push({ key: `exchangeTotalCountMember${i}`, width: 15, style: { numFmt: '#,##0' } })
        columns.push({ key: `exchangeGroupCountMember${i}`, width: 15, style: { numFmt: '#,##0' } })
        columns.push({ key: `exchangeTotalAmountMember${i}`, width: 15, style: { numFmt: '#,##0' } })
        columns.push({ key: `exchangeTotalCountNonMember${i}`, width: 15, style: { numFmt: '#,##0' } })
        columns.push({ key: `exchangeGroupCountNonMember${i}`, width: 15, style: { numFmt: '#,##0' } })
        columns.push({ key: `exchangeTotalAmountNonMember${i}`, width: 15, style: { numFmt: '#,##0' } })
      }
      // 전체
      columns.push({ key: 'exchangeTotalCountSumMember', width: 15, style: { numFmt: '#,##0' } })
      columns.push({ key: 'exchangeGroupCountSumMember', width: 15, style: { numFmt: '#,##0' } })
      columns.push({ key: 'exchangeTotalAmountSumMember', width: 15, style: { numFmt: '#,##0' } })
      columns.push({ key: 'exchangeTotalCountSumNonMember', width: 15, style: { numFmt: '#,##0' } })
      columns.push({ key: 'exchangeGroupCountSumNonMember', width: 15, style: { numFmt: '#,##0' } })
      columns.push({ key: 'exchangeTotalAmountSumNonMember', width: 15, style: { numFmt: '#,##0' } })

      worksheet.columns = columns

      // eslint-disable-next-line array-callback-return
      data.value.map(e => {
        const record = {
          date: (data.value.length === 1 && e.isPrefixSum) ? '누적 수' : (e.isPrefixSum ? "합계" : e.date),
        }
        for (let i = 0; i < e.products.length; i += 1) {
          record[`exchangeTotalCountMember${i}`] = e.products[i].exchangeTotalCountMember
          record[`exchangeGroupCountMember${i}`] = e.products[i].exchangeGroupCountMember
          record[`exchangeTotalAmountMember${i}`] = e.products[i].exchangeTotalAmountMember
          record[`exchangeTotalCountNonMember${i}`] = e.products[i].exchangeTotalCountNonMember
          record[`exchangeGroupCountNonMember${i}`] = e.products[i].exchangeGroupCountNonMember
          record[`exchangeTotalAmountNonMember${i}`] = e.products[i].exchangeTotalAmountNonMember
        }
        record.exchangeTotalCountSumMember = e.exchangeTotalCountMember
        record.exchangeGroupCountSumMember = e.exchangeGroupCountMember
        record.exchangeTotalAmountSumMember = e.exchangeTotalAmountMember
        record.exchangeTotalCountSumNonMember = e.exchangeTotalCountNonMember
        record.exchangeGroupCountSumNonMember = e.exchangeGroupCountNonMember
        record.exchangeTotalAmountSumNonMember = e.exchangeTotalAmountNonMember

        worksheet.addRow(record)
      })

      worksheet.eachRow({ includeEmpty: true }, (row, number) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (number < 4) {
            // eslint-disable-next-line no-param-reassign
            cell.font = {
              bold: true,
            }

            // eslint-disable-next-line no-param-reassign
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            }
          }

          // eslint-disable-next-line no-param-reassign
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }

          if (cell.value === '소계' || cell.value === '합계') {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
            }
          } else {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
            }
          }
        })
      })

      const mimeType = {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }

      const buffer = await workbook.xlsx.writeBuffer()
      const blob = new Blob([buffer], mimeType)

      saveAs(blob, "포인트교환현황.xlsx")
    }

    return {
      data,
      products,
      query,
      fetchData,
      changePrefixSum,
      excelDownload,
    }
  },
}
</script>

<style scoped>
.table-bordered th, td {
  border: 1px solid #dee2e6;
}

.table th {
  vertical-align: middle;
}

.sticky-header {
  position: sticky;
  z-index: 99 !important;
}

.dark-layout .sticky-row {
  position: sticky;
  z-index: 3 !important;
  background-color: #283046;
}

.sticky-row {
  position: sticky;
  z-index: 3 !important;
  background-color: #fff;
}
</style>
