<template>
  <b-table-simple
    bordered
    responsive
    sticky-header
    style="max-height: 65vh;"
  >
    <colgroup>
      <col style="width: 140px">
      <!-- 설문조사(자체-닥터인포) -->
      <col style="width: 110px">
      <col style="width: 120px">
      <col style="width: 160px">
      <col style="width: 110px">
      <col style="width: 120px">
      <col style="width: 160px">
      <col style="width: 110px">
      <col style="width: 130px">
      <col style="width: 160px">
      <!-- 설문조사(자체-MCM) -->
      <col style="width: 110px">
      <col style="width: 120px">
      <col style="width: 160px">
      <col style="width: 110px">
      <col style="width: 120px">
      <col style="width: 160px">
      <col style="width: 110px">
      <col style="width: 130px">
      <col style="width: 160px">
      <!-- 설문조사(제약사) -->
      <col style="width: 110px">
      <col style="width: 120px">
      <col style="width: 160px">
      <col style="width: 110px">
      <col style="width: 120px">
      <col style="width: 160px">
      <col style="width: 110px">
      <col style="width: 130px">
      <col style="width: 160px">
      <!-- 설문조사(이벤트) -->
      <col style="width: 110px">
      <col style="width: 120px">
      <col style="width: 160px">
      <col style="width: 110px">
      <col style="width: 120px">
      <col style="width: 160px">
      <col style="width: 110px">
      <col style="width: 130px">
      <col style="width: 160px">
      <!-- (월별미션) 댓글 작성 -->
      <col style="width: 110px">
      <col style="width: 120px">
      <col style="width: 160px">
      <!-- (월별미션) MY 세미나 등록 -->
      <col style="width: 110px">
      <col style="width: 120px">
      <col style="width: 160px">
      <!-- (월별미션) Weekly Choice -->
      <col style="width: 110px">
      <col style="width: 120px">
      <col style="width: 160px">
      <!-- 전체 -->
      <col style="width: 110px">
      <col style="width: 120px">
      <col style="width: 160px">
      <col style="width: 110px">
      <col style="width: 120px">
      <col style="width: 160px">
      <col style="width: 110px">
      <col style="width: 130px">
      <col style="width: 160px">
    </colgroup>
    <b-thead head-variant="light">
      <b-tr>
        <b-th
          rowspan="4"
          class="sticky-header"
          style="left: 0"
        >
          구분
        </b-th>
        <b-th colspan="9">
          설문조사(자체-닥터인포)
        </b-th>
        <b-th colspan="9">
          설문조사(자체-MCM)
        </b-th>
        <b-th colspan="9">
          설문조사(제약사)
        </b-th>
        <b-th colspan="9">
          이벤트
        </b-th>
        <b-th colspan="3">
          (월별미션) 댓글 작성
        </b-th>
        <b-th colspan="3">
          (월별미션) MY 세미나 등록
        </b-th>
        <b-th colspan="3">
          (월별미션) Weekly Choice
        </b-th>
        <b-th colspan="9">
          전체
        </b-th>
      </b-tr>
      <b-tr>
        <b-th
          rowspan="2"
          colspan="3"
          style="top: 38.55px;"
        >
          포인트
        </b-th>
        <b-th colspan="6">
          기프티콘
        </b-th>
        <b-th
          rowspan="2"
          colspan="3"
          style="top: 38.55px;"
        >
          포인트
        </b-th>
        <b-th colspan="6">
          기프티콘
        </b-th>
        <b-th
          rowspan="2"
          colspan="3"
          style="top: 38.55px;"
        >
          포인트
        </b-th>
        <b-th colspan="6">
          기프티콘
        </b-th>
        <b-th
          rowspan="2"
          colspan="3"
          style="top: 38.55px;"
        >
          포인트
        </b-th>
        <b-th colspan="6">
          기프티콘
        </b-th>
        <b-th
          rowspan="2"
          colspan="3"
          style="top: 38.55px;"
        >
          포인트
        </b-th>
        <b-th
          rowspan="2"
          colspan="3"
          style="top: 38.55px;"
        >
          포인트
        </b-th>
        <b-th
          rowspan="2"
          colspan="3"
          style="top: 38.55px;"
        >
          포인트
        </b-th>
        <b-th
          rowspan="2"
          colspan="3"
          style="top: 38.55px;"
        >
          포인트
        </b-th>
        <b-th colspan="6">
          기프티콘
        </b-th>
      </b-tr>
      <b-tr>
        <b-th colspan="3">
          회원
        </b-th>
        <b-th colspan="3">
          비회원
        </b-th>
        <b-th colspan="3">
          회원
        </b-th>
        <b-th colspan="3">
          비회원
        </b-th>
        <b-th colspan="3">
          회원
        </b-th>
        <b-th colspan="3">
          비회원
        </b-th>
        <b-th colspan="3">
          회원
        </b-th>
        <b-th colspan="3">
          비회원
        </b-th>
        <b-th colspan="3">
          회원
        </b-th>
        <b-th colspan="3">
          비회원
        </b-th>
      </b-tr>
      <b-tr>
        <b-th>
          적립 건수
        </b-th>
        <b-th>
          적립 회원수
        </b-th>
        <b-th>
          적립 금액
        </b-th>
        <b-th>
          적립 건수
        </b-th>
        <b-th>
          적립 회원수
        </b-th>
        <b-th>
          적립 금액
        </b-th>
        <b-th>
          적립 건수
        </b-th>
        <b-th>
          적립 비회원수
        </b-th>
        <b-th>
          적립 금액
        </b-th>
        <b-th>
          적립 건수
        </b-th>
        <b-th>
          적립 회원수
        </b-th>
        <b-th>
          적립 금액
        </b-th>
        <b-th>
          적립 건수
        </b-th>
        <b-th>
          적립 회원수
        </b-th>
        <b-th>
          적립 금액
        </b-th>
        <b-th>
          적립 건수
        </b-th>
        <b-th>
          적립 비회원수
        </b-th>
        <b-th>
          적립 금액
        </b-th>
        <b-th>
          적립 건수
        </b-th>
        <b-th>
          적립 회원수
        </b-th>
        <b-th>
          적립 금액
        </b-th>
        <b-th>
          적립 건수
        </b-th>
        <b-th>
          적립 회원수
        </b-th>
        <b-th>
          적립 금액
        </b-th>
        <b-th>
          적립 건수
        </b-th>
        <b-th>
          적립 비회원수
        </b-th>
        <b-th>
          적립 금액
        </b-th>
        <b-th>
          적립 건수
        </b-th>
        <b-th>
          적립 회원수
        </b-th>
        <b-th>
          적립 금액
        </b-th>
        <b-th>
          적립 건수
        </b-th>
        <b-th>
          적립 회원수
        </b-th>
        <b-th>
          적립 금액
        </b-th>
        <b-th>
          적립 건수
        </b-th>
        <b-th>
          적립 비회원수
        </b-th>
        <b-th>
          적립 금액
        </b-th>
        <b-th>
          적립 건수
        </b-th>
        <b-th>
          적립 회원수
        </b-th>
        <b-th>
          적립 금액
        </b-th>
        <b-th>
          적립 건수
        </b-th>
        <b-th>
          적립 회원수
        </b-th>
        <b-th>
          적립 금액
        </b-th>
        <b-th>
          적립 건수
        </b-th>
        <b-th>
          적립 회원수
        </b-th>
        <b-th>
          적립 금액
        </b-th>
        <b-th>
          적립 건수
        </b-th>
        <b-th>
          적립 회원수
        </b-th>
        <b-th>
          적립 금액
        </b-th>
        <b-th>
          적립 건수
        </b-th>
        <b-th>
          적립 회원수
        </b-th>
        <b-th>
          적립 금액
        </b-th>
        <b-th>
          적립 건수
        </b-th>
        <b-th>
          적립 비회원수
        </b-th>
        <b-th>
          적립 금액
        </b-th>
      </b-tr>
    </b-thead>
    <b-tbody v-if="data.length > 0">
      <b-tr
        v-for="(m, idx) in data"
        :key="`tr-${idx}`"
        :class="{ 'table-active': idx === data.length - 1 }"
      >
        <b-td
          class="sticky-row"
          style="left: 0"
        >{{ (data.length === 1 && m.isPrefixSum) ? '누적 수' : (m.isPrefixSum ? "합계" : m.date) }}</b-td>
        <b-td>{{ m.selfSurveyPointCount | $numberFormatter }}</b-td>
        <b-td>{{ m.selfSurveyPointGroup | $numberFormatter }}</b-td>
        <b-td>{{ m.selfSurveyPointAmount | $numberFormatter }}</b-td>
        <b-td>{{ m.selfSurveyGiftCountMember | $numberFormatter }}</b-td>
        <b-td>{{ m.selfSurveyGiftGroupMember | $numberFormatter }}</b-td>
        <b-td>{{ m.selfSurveyGiftAmountMember | $numberFormatter }}</b-td>
        <b-td>{{ m.selfSurveyGiftCountNonMember | $numberFormatter }}</b-td>
        <b-td>{{ m.selfSurveyGiftGroupNonMember | $numberFormatter }}</b-td>
        <b-td>{{ m.selfSurveyGiftAmountNonMember | $numberFormatter }}</b-td>
        <b-td>{{ m.selfMcmSurveyPointCount | $numberFormatter }}</b-td>
        <b-td>{{ m.selfMcmSurveyPointGroup | $numberFormatter }}</b-td>
        <b-td>{{ m.selfMcmSurveyPointAmount | $numberFormatter }}</b-td>
        <b-td>{{ m.selfMcmSurveyGiftCountMember | $numberFormatter }}</b-td>
        <b-td>{{ m.selfMcmSurveyGiftGroupMember | $numberFormatter }}</b-td>
        <b-td>{{ m.selfMcmSurveyGiftAmountMember | $numberFormatter }}</b-td>
        <b-td>{{ m.selfMcmSurveyGiftCountNonMember | $numberFormatter }}</b-td>
        <b-td>{{ m.selfMcmSurveyGiftGroupNonMember | $numberFormatter }}</b-td>
        <b-td>{{ m.selfMcmSurveyGiftAmountNonMember | $numberFormatter }}</b-td>
        <b-td>{{ m.clientSurveyPointCount | $numberFormatter }}</b-td>
        <b-td>{{ m.clientSurveyPointGroup | $numberFormatter }}</b-td>
        <b-td>{{ m.clientSurveyPointAmount | $numberFormatter }}</b-td>
        <b-td>{{ m.clientSurveyGiftCountMember | $numberFormatter }}</b-td>
        <b-td>{{ m.clientSurveyGiftGroupMember | $numberFormatter }}</b-td>
        <b-td>{{ m.clientSurveyGiftAmountMember | $numberFormatter }}</b-td>
        <b-td>{{ m.clientSurveyGiftCountNonMember | $numberFormatter }}</b-td>
        <b-td>{{ m.clientSurveyGiftGroupNonMember | $numberFormatter }}</b-td>
        <b-td>{{ m.clientSurveyGiftAmountNonMember | $numberFormatter }}</b-td>
        <b-td>{{ m.eventPointCount | $numberFormatter }}</b-td>
        <b-td>{{ m.eventPointGroup | $numberFormatter }}</b-td>
        <b-td>{{ m.eventPointAmount | $numberFormatter }}</b-td>
        <b-td>{{ m.eventGiftCountMember | $numberFormatter }}</b-td>
        <b-td>{{ m.eventGiftGroupMember | $numberFormatter }}</b-td>
        <b-td>{{ m.eventGiftAmountMember | $numberFormatter }}</b-td>
        <b-td>{{ m.eventGiftCountNonMember | $numberFormatter }}</b-td>
        <b-td>{{ m.eventGiftGroupNonMember | $numberFormatter }}</b-td>
        <b-td>{{ m.eventGiftAmountNonMember | $numberFormatter }}</b-td>
        <b-td>{{ m.medicalTrendCommentTotalCount | $numberFormatter }}</b-td>
        <b-td>{{ m.medicalTrendCommentGroupCount | $numberFormatter }}</b-td>
        <b-td>{{ m.medicalTrendCommentTotalPoint | $numberFormatter }}</b-td>
        <b-td>{{ m.mySeminarTotalCount | $numberFormatter }}</b-td>
        <b-td>{{ m.mySeminarGroupCount | $numberFormatter }}</b-td>
        <b-td>{{ m.mySeminarTotalPoint | $numberFormatter }}</b-td>
        <b-td>{{ m.weeklyChoiceTotalCount | $numberFormatter }}</b-td>
        <b-td>{{ m.weeklyChoiceGroupCount | $numberFormatter }}</b-td>
        <b-td>{{ m.weeklyChoiceTotalPoint | $numberFormatter }}</b-td>
        <b-td>{{ m.allPointCount | $numberFormatter }}</b-td>
        <b-td>{{ m.allPointGroup | $numberFormatter }}</b-td>
        <b-td>{{ m.allPointAmount | $numberFormatter }}</b-td>
        <b-td>{{ m.allGiftCountMember | $numberFormatter }}</b-td>
        <b-td>{{ m.allGiftGroupMember | $numberFormatter }}</b-td>
        <b-td>{{ m.allGiftAmountMember | $numberFormatter }}</b-td>
        <b-td>{{ m.allGiftCountNonMember | $numberFormatter }}</b-td>
        <b-td>{{ m.allGiftGroupNonMember | $numberFormatter }}</b-td>
        <b-td>{{ m.allGiftAmountNonMember | $numberFormatter }}</b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
import {
  BTableSimple, BThead, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import { onMounted } from '@vue/composition-api'

export default {
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  setup() {
    onMounted(() => {
    })
  },
}
</script>

<style scoped>
.table-bordered th, td {
  border: 1px solid #dee2e6;
}

.table th {
  vertical-align: middle;
}

.sticky-header {
  position: sticky;
  z-index: 99 !important;
}

.dark-layout .sticky-row {
  position: sticky;
  z-index: 3 !important;
  background-color: #283046;
}

.sticky-row {
  position: sticky;
  z-index: 3 !important;
  background-color: #fff;
}
</style>
