<template>
  <div>
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row class="mt-1">
          <b-col
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <h5 class="mb-0 ml-50">
              누적 포인트 적립/사용액
            </h5>
          </b-col>
          <b-col
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <h5 class="mb-0 ml-50">
              raw 파일 다운로드
            </h5>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col md="6">
            <b-table
              :items="tableItems.total"
              :fields="tableColumns.total"
              responsive
              sticky-header
            >
              <template #cell(sum)="data">
                <span class="text-nowrap">
                  {{ (data.item.add + data.item.use + data.item.expire) | $numberFormatter }}
                </span>
              </template>
              <template #cell()="data">
                <span class="text-nowrap">
                  {{ data.value | $numberFormatter }}
                </span>
              </template>
            </b-table>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                <p class="h5 mt-50 mr-50 ml-50">
                  기간 내 월별 내역
                </p>
                <v-date-picker
                  v-model="excelParams.monthStart"
                  type="month"
                  value-type="YYYYMM"
                  class="el-def"
                  style="width:150px"
                />
                <label class="ml-50 mr-50">~</label>
                <v-date-picker
                  v-model="excelParams.monthEnd"
                  type="month"
                  value-type="YYYYMM"
                  class="el-def"
                  style="width:150px"
                />
                <b-button
                  variant="primary"
                  class="ml-1"
                  @click.prevent="downloadExcelMonth()"
                >
                  다운로드
                </b-button>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                <p class="h5 mt-50 mr-50 ml-50">
                  기간 내 전체 raw
                </p>
                <v-date-picker
                  v-model="excelParams.rawStart"
                  type="month"
                  value-type="YYYYMM"
                  class="el-def"
                  style="width:150px"
                />
                <label class="ml-50 mr-50">~</label>
                <v-date-picker
                  v-model="excelParams.rawEnd"
                  type="month"
                  value-type="YYYYMM"
                  class="el-def"
                  style="width:150px"
                />
                <b-button
                  variant="primary"
                  class="ml-1"
                  @click.prevent="downloadExcelRaw()"
                >
                  다운로드
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!-- <b-row class="mt-3">
          <b-col
            md="12"
            class="d-flex align-items-center justify-content-start"
          >
            <h5 class="mb-0 ml-50">
              포인트 적립액
            </h5>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col md="12">
            <b-table
              :items="tableItems.add"
              :fields="tableColumns.add"
              responsive
              sticky-header
            >
              <template #thead-top>
                <tr>
                  <th>
                    <span class="sr-only">월</span>
                  </th>
                  <th colspan="2">
                    약품정보
                  </th>
                  <th colspan="2">
                    메디컬트렌드
                  </th>
                  <th colspan="2">
                    웹심포지엄
                  </th>
                  <th colspan="2">
                    WeeklyChoice
                  </th>
                  <th colspan="2">
                    설문조사
                  </th>
                  <th colspan="2">
                    기타 적립
                  </th>
                  <th colspan="2">
                    총합
                  </th>
                </tr>
              </template>
              <template slot="top-row">
                <td>
                  <span class="text-nowrap">소계</span>
                </td>
                <td>
                  <span class="text-nowrap">{{ tableItems.add.reduce((f, l) => f + l.drugInfoUser, 0) | $numberFormatter }}</span>
                </td>
                <td>
                  <span class="text-nowrap">{{ tableItems.add.reduce((f, l) => f + l.drugInfo, 0) | $numberFormatter }}</span>
                </td>
                <td>
                  <span class="text-nowrap">{{ tableItems.add.reduce((f, l) => f + l.medicalTrendUser, 0) | $numberFormatter }}</span>
                </td>
                <td>
                  <span class="text-nowrap">{{ tableItems.add.reduce((f, l) => f + l.medicalTrend, 0) | $numberFormatter }}</span>
                </td>
                <td>
                  <span class="text-nowrap">{{ tableItems.add.reduce((f, l) => f + l.webSeminarUser, 0) | $numberFormatter }}</span>
                </td>
                <td>
                  <span class="text-nowrap">{{ tableItems.add.reduce((f, l) => f + l.webSeminar, 0) | $numberFormatter }}</span>
                </td>
                <td>
                  <span class="text-nowrap">{{ tableItems.add.reduce((f, l) => f + l.weeklyChoiceUser, 0) | $numberFormatter }}</span>
                </td>
                <td>
                  <span class="text-nowrap">{{ tableItems.add.reduce((f, l) => f + l.weeklyChoice, 0) | $numberFormatter }}</span>
                </td>
                <td>
                  <span class="text-nowrap">{{ tableItems.add.reduce((f, l) => f + l.surveyUser, 0) | $numberFormatter }}</span>
                </td>
                <td>
                  <span class="text-nowrap">{{ tableItems.add.reduce((f, l) => f + l.survey, 0) | $numberFormatter }}</span>
                </td>
                <td>
                  <span class="text-nowrap">{{ tableItems.add.reduce((f, l) => f + l.eventUser + l.etcUser, 0) | $numberFormatter }}</span>
                </td>
                <td>
                  <span class="text-nowrap">{{ tableItems.add.reduce((f, l) => f + l.event + l.etc, 0) | $numberFormatter }}</span>
                </td>
                <td>
                  <span class="text-nowrap">
                    {{ tableItems.add.reduce((f, l) => f + (l.drugInfoUser + l.medicalTrendUser + l.webSeminarUser + l.weeklyChoiceUser + l.surveyUser + l.eventUser + l.etcUser), 0) | $numberFormatter }}
                  </span>
                </td>
                <td>
                  <span class="text-nowrap">
                    {{ tableItems.add.reduce((f, l) => f + (l.drugInfo + l.medicalTrend + l.webSeminar + l.weeklyChoice + l.survey + l.event + l.etc), 0) | $numberFormatter }}
                  </span>
                </td>
              </template>
              <template #cell(yearMonth)="data">
                <span class="text-nowrap">
                  {{ data.item.yearMonth }}
                </span>
              </template>
              <template #head(drugInfoUser)>
                달성<br>회원 수
              </template>
              <template #head(medicalTrendUser)>
                달성<br>회원 수
              </template>
              <template #head(webSeminarUser)>
                달성<br>회원 수
              </template>
              <template #head(weeklyChoiceUser)>
                달성<br>회원 수
              </template>
              <template #head(surveyUser)>
                참여<br>회원 수
              </template>
              <template #head(etcUser)>
                달성<br>회원 수
              </template>
              <template #head(sum)>
                총<br>적립액
              </template>
              <template #cell(etcUser)="data">
                <span class="text-nowrap">
                  {{ (data.item.eventUser + data.item.etcUser) | $numberFormatter }}
                </span>
              </template>
              <template #cell(etc)="data">
                <span class="text-nowrap">
                  {{ (data.item.event + data.item.etc) | $numberFormatter }}
                </span>
              </template>
              <template #cell(sumUser)="data">
                <span class="text-nowrap">
                  {{ (data.item.drugInfoUser + data.item.medicalTrendUser + data.item.webSeminarUser + data.item.weeklyChoiceUser + data.item.surveyUser + data.item.eventUser + data.item.etcUser) | $numberFormatter }}
                </span>
              </template>
              <template #cell(sum)="data">
                <span class="text-nowrap">
                  {{ (data.item.drugInfo + data.item.medicalTrend + data.item.webSeminar + data.item.weeklyChoice + data.item.survey + data.item.event + data.item.etc) | $numberFormatter }}
                </span>
              </template>
              <template #cell()="data">
                <span class="text-nowrap">
                  {{ data.value | $numberFormatter }}
                </span>
              </template>
            </b-table>
          </b-col>
        </b-row> -->
        <b-row class="mt-3">
          <b-col
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <h5 class="mb-0 ml-50">
              포인트 사용액
            </h5>
          </b-col>
          <b-col
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <h5 class="mb-0 ml-50">
              유효기간 만료액
            </h5>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col md="6">
            <b-table
              :items="tableItems.use"
              :fields="tableColumns.use"
              responsive
              sticky-header
            >
              <template slot="top-row">
                <td>
                  <span class="text-nowrap">소계</span>
                </td>
                <td>
                  <span class="text-nowrap">{{ tableItems.use.reduce((f, l) => f + l.total, 0) | $numberFormatter }}</span>
                </td>
                <td>
                  <span class="text-nowrap">{{ tableItems.use.reduce((f, l) => f + l.userCount, 0) | $numberFormatter }}</span>
                </td>
                <td>
                  <span class="text-nowrap">{{ tableItems.use.reduce((f, l) => f + l.priceTotal, 0) | $numberFormatter }}</span>
                </td>
              </template>
              <template #cell(yearMonth)="data">
                <span class="text-nowrap">
                  {{ data.item.yearMonth }}
                </span>
              </template>
              <template #cell(sum)="data">
                <span class="text-nowrap">
                  {{ (data.item.total) | $numberFormatter }}
                </span>
              </template>
              <template #cell()="data">
                <span class="text-nowrap">
                  {{ data.value | $numberFormatter }}
                </span>
              </template>
            </b-table>
          </b-col>
          <b-col md="6">
            <b-table
              :items="tableItems.expire"
              :fields="tableColumns.expire"
              responsive
              sticky-header
            >
              <template slot="top-row">
                <td>
                  <span class="text-nowrap">소계</span>
                </td>
                <td>
                  <span class="text-nowrap">{{ tableItems.expire.reduce((f, l) => f + l.total, 0) | $numberFormatter }}</span>
                </td>
              </template>
              <template #cell(yearMonth)="data">
                <span class="text-nowrap">
                  {{ data.item.yearMonth }}
                </span>
              </template>
              <template #cell(sum)="data">
                <span class="text-nowrap">
                  {{ (data.item.total) | $numberFormatter }}
                </span>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import { ref, onMounted } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dayjs from 'dayjs'
import { saveAs } from "file-saver"

export default {
  setup() {
    onMounted(() => {
      fetchData()
    })

    const toast = useToast()

    const excelParams = ref({
      monthStart: dayjs().format('YYYYMM'),
      monthEnd: dayjs().format('YYYYMM'),
      rawStart: dayjs().format('YYYYMM'),
      rawEnd: dayjs().format('YYYYMM'),
    })

    const tableItems = ref({
      total: [],
      use: [],
      add: [],
      expire: [],
      recentlySixMonth: [],
    })

    const fetchData = () => {
      axios.get('/fa/pointhistory')
        .then(rs => {
          const {
            total, use, add, expire, recentlySixMonth,
          } = rs.data

          tableItems.value.total.push(total)
          tableItems.value.use = use
          tableItems.value.add = add
          tableItems.value.expire = expire
          tableItems.value.recentlySixMonth = recentlySixMonth
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '데이터 조회 중 오류가 발생하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const tableColumns = {
      total: [
        { key: 'add', label: '누적 포인트 적립 내역' },
        { key: 'use', label: '누적 포인트 사용 내역' },
        { key: 'expire', label: '유효기간 만료 포인트 내역' },
        { key: 'sum', label: '잔여 포인트' },
      ],
      add: [
        { key: 'yearMonth', label: '월', thClass: 'align-middle' },
        { key: 'drugInfoUser', label: '달성 회원 수' },
        { key: 'drugInfo', label: '적립액', thClass: 'align-middle' },
        { key: 'medicalTrendUser', label: '달성 회원 수' },
        { key: 'medicalTrend', label: '적립액', thClass: 'align-middle' },
        { key: 'webSeminarUser', label: '달성 회원 수' },
        { key: 'webSeminar', label: '적립액', thClass: 'align-middle' },
        { key: 'weeklyChoiceUser', label: '달성 회원 수' },
        { key: 'weeklyChoice', label: '적립액', thClass: 'align-middle' },
        { key: 'surveyUser', label: '참여 회원 수' },
        { key: 'survey', label: '적립액', thClass: 'align-middle' },
        { key: 'etcUser', label: '참여 회원 수' },
        { key: 'etc', label: '적립액', thClass: 'align-middle' },
        { key: 'sumUser', label: '총 달성 회원 수' },
        { key: 'sum', label: '총 적립액', thClass: 'align-middle' },
      ],
      use: [
        { key: 'yearMonth', label: '월' },
        { key: 'total', label: '포인트 사용액' },
        { key: 'userCount', label: '사용 회원 수' },
        { key: 'priceTotal', label: '실제 지불 액' },
      ],
      expire: [
        { key: 'yearMonth', label: '월' },
        { key: 'total', label: '포인트 만료액' },
      ],
    }

    const downloadExcelMonth = () => {
      const startMonth = dayjs(excelParams.value.monthStart).format('YYYY-MM-DD')
      const endMonth = dayjs(excelParams.value.monthEnd).format('YYYY-MM-DD')

      axios.get(`/fa/pointhistory/excel/month/${startMonth}/${endMonth}`, { responseType: 'blob' })
        .then(rs => {
          const blob = new Blob([rs.data])

          const fileName = `포인트월별내역_${dayjs(startMonth).format('YYYYMM')}_${dayjs(endMonth).format('YYYYMM')}`

          saveAs(blob, `${fileName}.xlsx`)
        })
        .catch(() => {
        })
    }

    const downloadExcelRaw = () => {
      const startMonth = dayjs(excelParams.value.rawStart).format('YYYY-MM-DD')
      const endMonth = dayjs(excelParams.value.rawEnd).format('YYYY-MM-DD')

      axios.get(`/fa/pointhistory/excel/raw/${startMonth}/${endMonth}`, { responseType: 'blob' })
        .then(rs => {
          const blob = new Blob([rs.data])

          const fileName = `포인트전체Raw_${dayjs(startMonth).format('YYYYMM')}_${dayjs(endMonth).format('YYYYMM')}`

          saveAs(blob, `${fileName}.xlsx`)
        })
        .catch(() => {
        })
    }

    return {
      tableColumns,
      tableItems,
      excelParams,
      downloadExcelMonth,
      downloadExcelRaw,
    }
  },
}
</script>

<style>
th, td {
  text-align: center;
}

.align-middle {
  vertical-align: middle;
}
</style>
