var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-table-simple",
    {
      staticStyle: { "max-height": "65vh" },
      attrs: { bordered: "", responsive: "", "sticky-header": "" },
    },
    [
      _c("colgroup", [
        _c("col", { staticStyle: { width: "140px" } }),
        _c("col", { staticStyle: { width: "110px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "160px" } }),
        _c("col", { staticStyle: { width: "110px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "160px" } }),
        _c("col", { staticStyle: { width: "110px" } }),
        _c("col", { staticStyle: { width: "130px" } }),
        _c("col", { staticStyle: { width: "160px" } }),
        _c("col", { staticStyle: { width: "110px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "160px" } }),
        _c("col", { staticStyle: { width: "110px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "160px" } }),
        _c("col", { staticStyle: { width: "110px" } }),
        _c("col", { staticStyle: { width: "130px" } }),
        _c("col", { staticStyle: { width: "160px" } }),
        _c("col", { staticStyle: { width: "110px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "160px" } }),
        _c("col", { staticStyle: { width: "110px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "160px" } }),
        _c("col", { staticStyle: { width: "110px" } }),
        _c("col", { staticStyle: { width: "130px" } }),
        _c("col", { staticStyle: { width: "160px" } }),
        _c("col", { staticStyle: { width: "110px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "160px" } }),
        _c("col", { staticStyle: { width: "110px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "160px" } }),
        _c("col", { staticStyle: { width: "110px" } }),
        _c("col", { staticStyle: { width: "130px" } }),
        _c("col", { staticStyle: { width: "160px" } }),
        _c("col", { staticStyle: { width: "110px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "160px" } }),
        _c("col", { staticStyle: { width: "110px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "160px" } }),
        _c("col", { staticStyle: { width: "110px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "160px" } }),
        _c("col", { staticStyle: { width: "110px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "160px" } }),
        _c("col", { staticStyle: { width: "110px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "160px" } }),
        _c("col", { staticStyle: { width: "110px" } }),
        _c("col", { staticStyle: { width: "130px" } }),
        _c("col", { staticStyle: { width: "160px" } }),
      ]),
      _c(
        "b-thead",
        { attrs: { "head-variant": "light" } },
        [
          _c(
            "b-tr",
            [
              _c(
                "b-th",
                {
                  staticClass: "sticky-header",
                  staticStyle: { left: "0" },
                  attrs: { rowspan: "4" },
                },
                [_vm._v(" 구분 ")]
              ),
              _c("b-th", { attrs: { colspan: "9" } }, [
                _vm._v(" 설문조사(자체-닥터인포) "),
              ]),
              _c("b-th", { attrs: { colspan: "9" } }, [
                _vm._v(" 설문조사(자체-MCM) "),
              ]),
              _c("b-th", { attrs: { colspan: "9" } }, [
                _vm._v(" 설문조사(제약사) "),
              ]),
              _c("b-th", { attrs: { colspan: "9" } }, [_vm._v(" 이벤트 ")]),
              _c("b-th", { attrs: { colspan: "3" } }, [
                _vm._v(" (월별미션) 댓글 작성 "),
              ]),
              _c("b-th", { attrs: { colspan: "3" } }, [
                _vm._v(" (월별미션) MY 세미나 등록 "),
              ]),
              _c("b-th", { attrs: { colspan: "3" } }, [
                _vm._v(" (월별미션) Weekly Choice "),
              ]),
              _c("b-th", { attrs: { colspan: "9" } }, [_vm._v(" 전체 ")]),
            ],
            1
          ),
          _c(
            "b-tr",
            [
              _c(
                "b-th",
                {
                  staticStyle: { top: "38.55px" },
                  attrs: { rowspan: "2", colspan: "3" },
                },
                [_vm._v(" 포인트 ")]
              ),
              _c("b-th", { attrs: { colspan: "6" } }, [_vm._v(" 기프티콘 ")]),
              _c(
                "b-th",
                {
                  staticStyle: { top: "38.55px" },
                  attrs: { rowspan: "2", colspan: "3" },
                },
                [_vm._v(" 포인트 ")]
              ),
              _c("b-th", { attrs: { colspan: "6" } }, [_vm._v(" 기프티콘 ")]),
              _c(
                "b-th",
                {
                  staticStyle: { top: "38.55px" },
                  attrs: { rowspan: "2", colspan: "3" },
                },
                [_vm._v(" 포인트 ")]
              ),
              _c("b-th", { attrs: { colspan: "6" } }, [_vm._v(" 기프티콘 ")]),
              _c(
                "b-th",
                {
                  staticStyle: { top: "38.55px" },
                  attrs: { rowspan: "2", colspan: "3" },
                },
                [_vm._v(" 포인트 ")]
              ),
              _c("b-th", { attrs: { colspan: "6" } }, [_vm._v(" 기프티콘 ")]),
              _c(
                "b-th",
                {
                  staticStyle: { top: "38.55px" },
                  attrs: { rowspan: "2", colspan: "3" },
                },
                [_vm._v(" 포인트 ")]
              ),
              _c(
                "b-th",
                {
                  staticStyle: { top: "38.55px" },
                  attrs: { rowspan: "2", colspan: "3" },
                },
                [_vm._v(" 포인트 ")]
              ),
              _c(
                "b-th",
                {
                  staticStyle: { top: "38.55px" },
                  attrs: { rowspan: "2", colspan: "3" },
                },
                [_vm._v(" 포인트 ")]
              ),
              _c(
                "b-th",
                {
                  staticStyle: { top: "38.55px" },
                  attrs: { rowspan: "2", colspan: "3" },
                },
                [_vm._v(" 포인트 ")]
              ),
              _c("b-th", { attrs: { colspan: "6" } }, [_vm._v(" 기프티콘 ")]),
            ],
            1
          ),
          _c(
            "b-tr",
            [
              _c("b-th", { attrs: { colspan: "3" } }, [_vm._v(" 회원 ")]),
              _c("b-th", { attrs: { colspan: "3" } }, [_vm._v(" 비회원 ")]),
              _c("b-th", { attrs: { colspan: "3" } }, [_vm._v(" 회원 ")]),
              _c("b-th", { attrs: { colspan: "3" } }, [_vm._v(" 비회원 ")]),
              _c("b-th", { attrs: { colspan: "3" } }, [_vm._v(" 회원 ")]),
              _c("b-th", { attrs: { colspan: "3" } }, [_vm._v(" 비회원 ")]),
              _c("b-th", { attrs: { colspan: "3" } }, [_vm._v(" 회원 ")]),
              _c("b-th", { attrs: { colspan: "3" } }, [_vm._v(" 비회원 ")]),
              _c("b-th", { attrs: { colspan: "3" } }, [_vm._v(" 회원 ")]),
              _c("b-th", { attrs: { colspan: "3" } }, [_vm._v(" 비회원 ")]),
            ],
            1
          ),
          _c(
            "b-tr",
            [
              _c("b-th", [_vm._v(" 적립 건수 ")]),
              _c("b-th", [_vm._v(" 적립 회원수 ")]),
              _c("b-th", [_vm._v(" 적립 금액 ")]),
              _c("b-th", [_vm._v(" 적립 건수 ")]),
              _c("b-th", [_vm._v(" 적립 회원수 ")]),
              _c("b-th", [_vm._v(" 적립 금액 ")]),
              _c("b-th", [_vm._v(" 적립 건수 ")]),
              _c("b-th", [_vm._v(" 적립 비회원수 ")]),
              _c("b-th", [_vm._v(" 적립 금액 ")]),
              _c("b-th", [_vm._v(" 적립 건수 ")]),
              _c("b-th", [_vm._v(" 적립 회원수 ")]),
              _c("b-th", [_vm._v(" 적립 금액 ")]),
              _c("b-th", [_vm._v(" 적립 건수 ")]),
              _c("b-th", [_vm._v(" 적립 회원수 ")]),
              _c("b-th", [_vm._v(" 적립 금액 ")]),
              _c("b-th", [_vm._v(" 적립 건수 ")]),
              _c("b-th", [_vm._v(" 적립 비회원수 ")]),
              _c("b-th", [_vm._v(" 적립 금액 ")]),
              _c("b-th", [_vm._v(" 적립 건수 ")]),
              _c("b-th", [_vm._v(" 적립 회원수 ")]),
              _c("b-th", [_vm._v(" 적립 금액 ")]),
              _c("b-th", [_vm._v(" 적립 건수 ")]),
              _c("b-th", [_vm._v(" 적립 회원수 ")]),
              _c("b-th", [_vm._v(" 적립 금액 ")]),
              _c("b-th", [_vm._v(" 적립 건수 ")]),
              _c("b-th", [_vm._v(" 적립 비회원수 ")]),
              _c("b-th", [_vm._v(" 적립 금액 ")]),
              _c("b-th", [_vm._v(" 적립 건수 ")]),
              _c("b-th", [_vm._v(" 적립 회원수 ")]),
              _c("b-th", [_vm._v(" 적립 금액 ")]),
              _c("b-th", [_vm._v(" 적립 건수 ")]),
              _c("b-th", [_vm._v(" 적립 회원수 ")]),
              _c("b-th", [_vm._v(" 적립 금액 ")]),
              _c("b-th", [_vm._v(" 적립 건수 ")]),
              _c("b-th", [_vm._v(" 적립 비회원수 ")]),
              _c("b-th", [_vm._v(" 적립 금액 ")]),
              _c("b-th", [_vm._v(" 적립 건수 ")]),
              _c("b-th", [_vm._v(" 적립 회원수 ")]),
              _c("b-th", [_vm._v(" 적립 금액 ")]),
              _c("b-th", [_vm._v(" 적립 건수 ")]),
              _c("b-th", [_vm._v(" 적립 회원수 ")]),
              _c("b-th", [_vm._v(" 적립 금액 ")]),
              _c("b-th", [_vm._v(" 적립 건수 ")]),
              _c("b-th", [_vm._v(" 적립 회원수 ")]),
              _c("b-th", [_vm._v(" 적립 금액 ")]),
              _c("b-th", [_vm._v(" 적립 건수 ")]),
              _c("b-th", [_vm._v(" 적립 회원수 ")]),
              _c("b-th", [_vm._v(" 적립 금액 ")]),
              _c("b-th", [_vm._v(" 적립 건수 ")]),
              _c("b-th", [_vm._v(" 적립 회원수 ")]),
              _c("b-th", [_vm._v(" 적립 금액 ")]),
              _c("b-th", [_vm._v(" 적립 건수 ")]),
              _c("b-th", [_vm._v(" 적립 비회원수 ")]),
              _c("b-th", [_vm._v(" 적립 금액 ")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.data.length > 0
        ? _c(
            "b-tbody",
            _vm._l(_vm.data, function (m, idx) {
              return _c(
                "b-tr",
                {
                  key: "tr-" + idx,
                  class: { "table-active": idx === _vm.data.length - 1 },
                },
                [
                  _c(
                    "b-td",
                    { staticClass: "sticky-row", staticStyle: { left: "0" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.data.length === 1 && m.isPrefixSum
                            ? "누적 수"
                            : m.isPrefixSum
                            ? "합계"
                            : m.date
                        )
                      ),
                    ]
                  ),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(_vm._f("$numberFormatter")(m.selfSurveyPointCount))
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(_vm._f("$numberFormatter")(m.selfSurveyPointGroup))
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.selfSurveyPointAmount)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.selfSurveyGiftCountMember)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.selfSurveyGiftGroupMember)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.selfSurveyGiftAmountMember)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          m.selfSurveyGiftCountNonMember
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          m.selfSurveyGiftGroupNonMember
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          m.selfSurveyGiftAmountNonMember
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.selfMcmSurveyPointCount)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.selfMcmSurveyPointGroup)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.selfMcmSurveyPointAmount)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          m.selfMcmSurveyGiftCountMember
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          m.selfMcmSurveyGiftGroupMember
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          m.selfMcmSurveyGiftAmountMember
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          m.selfMcmSurveyGiftCountNonMember
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          m.selfMcmSurveyGiftGroupNonMember
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          m.selfMcmSurveyGiftAmountNonMember
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.clientSurveyPointCount)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.clientSurveyPointGroup)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.clientSurveyPointAmount)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          m.clientSurveyGiftCountMember
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          m.clientSurveyGiftGroupMember
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          m.clientSurveyGiftAmountMember
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          m.clientSurveyGiftCountNonMember
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          m.clientSurveyGiftGroupNonMember
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          m.clientSurveyGiftAmountNonMember
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(_vm._f("$numberFormatter")(m.eventPointCount))
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(_vm._f("$numberFormatter")(m.eventPointGroup))
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(_vm._f("$numberFormatter")(m.eventPointAmount))
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(_vm._f("$numberFormatter")(m.eventGiftCountMember))
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(_vm._f("$numberFormatter")(m.eventGiftGroupMember))
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.eventGiftAmountMember)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.eventGiftCountNonMember)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.eventGiftGroupNonMember)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.eventGiftAmountNonMember)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          m.medicalTrendCommentTotalCount
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          m.medicalTrendCommentGroupCount
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          m.medicalTrendCommentTotalPoint
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(_vm._f("$numberFormatter")(m.mySeminarTotalCount))
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(_vm._f("$numberFormatter")(m.mySeminarGroupCount))
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(_vm._f("$numberFormatter")(m.mySeminarTotalPoint))
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.weeklyChoiceTotalCount)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.weeklyChoiceGroupCount)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.weeklyChoiceTotalPoint)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(_vm._s(_vm._f("$numberFormatter")(m.allPointCount))),
                  ]),
                  _c("b-td", [
                    _vm._v(_vm._s(_vm._f("$numberFormatter")(m.allPointGroup))),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(_vm._f("$numberFormatter")(m.allPointAmount))
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(_vm._f("$numberFormatter")(m.allGiftCountMember))
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(_vm._f("$numberFormatter")(m.allGiftGroupMember))
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(_vm._f("$numberFormatter")(m.allGiftAmountMember))
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.allGiftCountNonMember)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.allGiftGroupNonMember)
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(m.allGiftAmountNonMember)
                      )
                    ),
                  ]),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }