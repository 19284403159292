<template>
  <div>
    <b-row>
      <b-col
        md="8"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <p class="h5 mt-50 mr-75 ml-50">
          일별/누적
        </p>
        <b-form-radio-group
          v-model="query.isPrefixSum"
          @change="changePrefixSum"
        >
          <b-form-radio :value="false">
            일별 (기간:최대30일)
          </b-form-radio>
          <b-form-radio :value="true">
            누적 (기간:최대1년)
          </b-form-radio>
        </b-form-radio-group>
        <b-form-datepicker
          v-model="query.startDate"
          class="el-def"
          placeholder="조회기간(Fr)"
          style="width: 240px"
        />
        <label class="ml-50 mr-50">~</label>
        <b-form-datepicker
          v-model="query.endDate"
          class="el-def"
          placeholder="조회기간(To)"
          style="width: 240px"
        />
      </b-col>
      <b-col
        md="4"
        class="d-flex align-items-center justify-content-end"
      >
        <b-button
          variant="primary"
          class="btn-search"
          @click.prevent="fetchData()"
        >
          조회
        </b-button>
        <b-button
          variant="outline-dark"
          class="btn-search ml-1"
          @click.prevent="excelDownload"
        >
          <feather-icon
            icon="DownloadIcon"
            class="mr-50"
          />
          <span>엑셀 다운로드</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col
        md="6"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <p class="h5 mt-50 mr-75 ml-50">
          조회 타입
        </p>
        <b-form-radio-group
          v-model="query.viewType"
        >
          <b-form-radio value="summary">
            요약
          </b-form-radio>
          <b-form-radio value="detail">
            상세
          </b-form-radio>
        </b-form-radio-group>
      </b-col>
    </b-row>
    <b-row class="mt-50">
      <b-col
        cols="12"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <summary-grid
          v-if="viewType === 'summary'"
          :data="data"
        />

        <detail-grid
          v-if="viewType === 'detail'"
          :data="data"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import axios from '@axios'
import { onMounted, ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ExcelJS from "exceljs"
import { saveAs } from "file-saver"
import SummaryGrid from './EarningSummaryGrid.vue'
import DetailGrid from './EarningDetailGrid.vue'

export default {
  components: {
    SummaryGrid,
    DetailGrid,
  },
  setup() {
    const data = ref([])

    onMounted(() => {
      fetchData()
    })

    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon

      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }

      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    const changePrefixSum = () => {
      if (query.value.isPrefixSum) {
        query.value.startDate = dayjs().add(-1, 'month').format('YYYY-MM-DD')
      } else {
        query.value.startDate = dayjs().add(-6, 'day').format('YYYY-MM-DD')
      }

      query.value.endDate = dayjs().format('YYYY-MM-DD')
    }

    const viewType = ref("summary")

    const query = ref({
      viewType: 'summary',
      startDate: dayjs().add(-6, 'day').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
      isPrefixSum: false,
    })

    const fetchData = () => {
      const frDate = dayjs(query.value.startDate)
      const toDate = dayjs(query.value.endDate)

      if (query.value.isPrefixSum && Math.abs(frDate.diff(toDate, 'day')) > 365) {
        pushToast('warning', '조회 기간은 최대 1년을 넘을 수 없습니다.')
        return
      }
      if (query.value.isPrefixSum === false && Math.abs(frDate.diff(toDate, 'day')) > 30) {
        pushToast('warning', '조회 기간은 최대 30일을 넘을 수 없습니다.')
        return
      }

      axios.get(`/fa/stats-user-point/earning/${query.value.viewType}`, {
        params: {
          startDate: query.value.startDate,
          endDate: query.value.endDate,
          isPrefixSum: query.value.isPrefixSum,
        },
      })
        .then(rs => {
          viewType.value = query.value.viewType

          data.value = rs.data
        })
        .catch(() => {
          pushToast('danger', '데이터 조회 중 오류가 발생하였습니다.')
        })
    }

    const excelDownload = async () => {
      if (viewType.value === 'summary') summaryDownload()
      else if (viewType.value === 'detail') detailDownload()
    }

    const summaryDownload = async () => {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet("sheet1")

      worksheet.addRow()

      worksheet.getCell('A1').value = '구분'
      worksheet.mergeCells(`A1:A2`)
      worksheet.getCell('B1').value = '설문조사(자체-닥터인포)'
      worksheet.mergeCells(`B1:E1`)
      worksheet.getCell('F1').value = '설문조사(자체-MCM)'
      worksheet.mergeCells(`F1:I1`)
      worksheet.getCell('J1').value = '설문조사(제약사)'
      worksheet.mergeCells(`J1:M1`)
      worksheet.getCell('N1').value = '이벤트'
      worksheet.mergeCells(`N1:Q1`)
      worksheet.getCell('R1').value = '(월별미션) 댓글 작성'
      worksheet.mergeCells(`R1:T1`)
      worksheet.getCell('U1').value = '(월별미션) MY 세미나 등록'
      worksheet.mergeCells(`U1:W1`)
      worksheet.getCell('X1').value = '(월별 미션) Weekly Choice'
      worksheet.mergeCells(`X1:Z1`)
      worksheet.getCell('AA1').value = '전체'
      worksheet.mergeCells(`AA1:AD1`)

      worksheet.getCell('B2').value = '적립 건수'
      worksheet.getCell('C2').value = '적립 회원수'
      worksheet.getCell('D2').value = '적립 비회원수'
      worksheet.getCell('E2').value = '적립 금액'
      worksheet.getCell('F2').value = '적립 건수'
      worksheet.getCell('G2').value = '적립 회원수'
      worksheet.getCell('H2').value = '적립 비회원수'
      worksheet.getCell('I2').value = '적립 금액'
      worksheet.getCell('J2').value = '적립 건수'
      worksheet.getCell('K2').value = '적립 회원수'
      worksheet.getCell('L2').value = '적립 비회원수'
      worksheet.getCell('M2').value = '적립 금액'
      worksheet.getCell('N2').value = '적립 건수'
      worksheet.getCell('O2').value = '적립 회원수'
      worksheet.getCell('P2').value = '적립 비회원수'
      worksheet.getCell('Q2').value = '적립 금액'
      worksheet.getCell('R2').value = '적립 건수'
      worksheet.getCell('S2').value = '적립 회원수'
      worksheet.getCell('T2').value = '적립 금액'
      worksheet.getCell('U2').value = '적립 건수'
      worksheet.getCell('V2').value = '적립 회원수'
      worksheet.getCell('W2').value = '적립 금액'
      worksheet.getCell('X2').value = '적립 건수'
      worksheet.getCell('Y2').value = '적립 회원수'
      worksheet.getCell('Z2').value = '적립 금액'
      worksheet.getCell('AA2').value = '적립 건수'
      worksheet.getCell('AB2').value = '적립 회원수'
      worksheet.getCell('AC2').value = '적립 비회원수'
      worksheet.getCell('AD2').value = '적립 금액'

      const columns = [
        { key: "date", width: 20 },
        { key: "selfSurveyTotalCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "selfSurveyGroupMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "selfSurveyGroupNonMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "selfSurveyTotalAmount", width: 15, style: { numFmt: '#,##0' } },
        { key: "selfMcmSurveyTotalCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "selfMcmSurveyGroupMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "selfMcmSurveyGroupNonMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "selfMcmSurveyTotalAmount", width: 15, style: { numFmt: '#,##0' } },
        { key: "clientSurveyTotalCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "clientSurveyGroupMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "clientSurveyGroupNonMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "clientSurveyTotalAmount", width: 15, style: { numFmt: '#,##0' } },
        { key: "eventTotalCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "eventGroupMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "eventGroupNonMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "eventTotalAmount", width: 15, style: { numFmt: '#,##0' } },
        { key: "medicalTrendCommentTotalCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "medicalTrendCommentGroupCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "medicalTrendCommentTotalPoint", width: 15, style: { numFmt: '#,##0' } },
        { key: "mySeminarTotalCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "mySeminarGroupCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "mySeminarTotalPoint", width: 15, style: { numFmt: '#,##0' } },
        { key: "weeklyChoiceTotalCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "weeklyChoiceGroupCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "weeklyChoiceTotalPoint", width: 15, style: { numFmt: '#,##0' } },
        { key: "allTotalCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "allGroupMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "allGroupNonMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "allTotalAmount", width: 15, style: { numFmt: '#,##0' } },
      ]

      worksheet.columns = columns

      // eslint-disable-next-line array-callback-return
      data.value.map(e => {
        const record = {
          date: (data.value.length === 1 && e.isPrefixSum) ? '누적 수' : (e.isPrefixSum ? "합계" : e.date),
          selfSurveyTotalCount: e.selfSurveyTotalCount,
          selfSurveyGroupMember: e.selfSurveyGroupMember,
          selfSurveyGroupNonMember: e.selfSurveyGroupNonMember,
          selfSurveyTotalAmount: e.selfSurveyTotalAmount,
          selfMcmSurveyTotalCount: e.selfMcmSurveyTotalCount,
          selfMcmSurveyGroupMember: e.selfMcmSurveyGroupMember,
          selfMcmSurveyGroupNonMember: e.selfMcmSurveyGroupNonMember,
          selfMcmSurveyTotalAmount: e.selfMcmSurveyTotalAmount,
          clientSurveyTotalCount: e.clientSurveyTotalCount,
          clientSurveyGroupMember: e.clientSurveyGroupMember,
          clientSurveyGroupNonMember: e.clientSurveyGroupNonMember,
          clientSurveyTotalAmount: e.clientSurveyTotalAmount,
          eventTotalCount: e.eventTotalCount,
          eventGroupMember: e.eventGroupMember,
          eventGroupNonMember: e.eventGroupNonMember,
          eventTotalAmount: e.eventTotalAmount,
          medicalTrendCommentTotalCount: e.medicalTrendCommentTotalCount,
          medicalTrendCommentGroupCount: e.medicalTrendCommentGroupCount,
          medicalTrendCommentTotalPoint: e.medicalTrendCommentTotalPoint,
          mySeminarTotalCount: e.mySeminarTotalCount,
          mySeminarGroupCount: e.mySeminarGroupCount,
          mySeminarTotalPoint: e.mySeminarTotalPoint,
          weeklyChoiceTotalCount: e.weeklyChoiceTotalCount,
          weeklyChoiceGroupCount: e.weeklyChoiceGroupCount,
          weeklyChoiceTotalPoint: e.weeklyChoiceTotalPoint,
          allTotalCount: e.allTotalCount,
          allGroupMember: e.allGroupMember,
          allGroupNonMember: e.allGroupNonMember,
          allTotalAmount: e.allTotalAmount,
        }

        worksheet.addRow(record)
      })

      worksheet.eachRow({ includeEmpty: true }, (row, number) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (number === 1 || number === 2) {
            // eslint-disable-next-line no-param-reassign
            cell.font = {
              bold: true,
            }

            // eslint-disable-next-line no-param-reassign
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            }
          }

          // eslint-disable-next-line no-param-reassign
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }

          if (cell.value === '소계' || cell.value === '합계') {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
            }
          } else {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
            }
          }
        })
      })

      const mimeType = {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }

      const buffer = await workbook.xlsx.writeBuffer()
      const blob = new Blob([buffer], mimeType)

      saveAs(blob, "포인트적립현황_요약.xlsx")
    }

    const detailDownload = async () => {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet("sheet1")

      worksheet.addRow()

      worksheet.getCell('A1').value = '구분'
      worksheet.mergeCells(`A1:A4`)
      worksheet.getCell('B1').value = '설문조사(자체-닥터인포)'
      worksheet.mergeCells(`B1:J1`)
      worksheet.getCell('K1').value = '설문조사(자체-MCM)'
      worksheet.mergeCells(`K1:S1`)
      worksheet.getCell('T1').value = '설문조사(제약사)'
      worksheet.mergeCells(`T1:AB1`)
      worksheet.getCell('AC1').value = '이벤트'
      worksheet.mergeCells(`AC1:AK1`)
      worksheet.getCell('AL1').value = '(월별미션) 댓글 작성'
      worksheet.mergeCells(`AL1:AN1`)
      worksheet.getCell('AO1').value = '(월별미션) MY 세미나 등록'
      worksheet.mergeCells(`AO1:AQ1`)
      worksheet.getCell('AR1').value = '(월별 미션) Weekly Choice'
      worksheet.mergeCells(`AR1:AT1`)
      worksheet.getCell('AU1').value = '전체'
      worksheet.mergeCells(`AU1:BC1`)

      worksheet.getCell('B2').value = '포인트'
      worksheet.mergeCells(`B2:D3`)
      worksheet.getCell('E2').value = '기프티콘'
      worksheet.mergeCells(`E2:J2`)
      worksheet.getCell('K2').value = '포인트'
      worksheet.mergeCells(`K2:M3`)
      worksheet.getCell('N2').value = '기프티콘'
      worksheet.mergeCells(`N2:S2`)
      worksheet.getCell('T2').value = '포인트'
      worksheet.mergeCells(`T2:V3`)
      worksheet.getCell('W2').value = '기프티콘'
      worksheet.mergeCells(`W2:AB2`)
      worksheet.getCell('AC2').value = '포인트'
      worksheet.mergeCells(`AC2:AE3`)
      worksheet.getCell('AF2').value = '기프티콘'
      worksheet.mergeCells(`AF2:AK2`)
      worksheet.getCell('AL2').value = '포인트'
      worksheet.mergeCells(`AL2:AN3`)
      worksheet.getCell('AO2').value = '포인트'
      worksheet.mergeCells(`AO2:AQ3`)
      worksheet.getCell('AR2').value = '포인트'
      worksheet.mergeCells(`AR2:AT3`)
      worksheet.getCell('AU2').value = '포인트'
      worksheet.mergeCells(`AU2:AW3`)
      worksheet.getCell('AX2').value = '기프티콘'
      worksheet.mergeCells(`AX2:BC2`)

      worksheet.getCell('E3').value = '회원'
      worksheet.mergeCells(`E3:G3`)
      worksheet.getCell('H3').value = '비회원'
      worksheet.mergeCells(`H3:J3`)
      worksheet.getCell('N3').value = '회원'
      worksheet.mergeCells(`N3:P3`)
      worksheet.getCell('Q3').value = '비회원'
      worksheet.mergeCells(`Q3:S3`)
      worksheet.getCell('W3').value = '회원'
      worksheet.mergeCells(`W3:Y3`)
      worksheet.getCell('Z3').value = '비회원'
      worksheet.mergeCells(`Z3:AB3`)
      worksheet.getCell('AF3').value = '회원'
      worksheet.mergeCells(`AF3:AH3`)
      worksheet.getCell('AI3').value = '비회원'
      worksheet.mergeCells(`AI3:AK3`)
      worksheet.getCell('AX3').value = '회원'
      worksheet.mergeCells(`AX3:AZ3`)
      worksheet.getCell('BA3').value = '비회원'
      worksheet.mergeCells(`BA3:BC3`)

      worksheet.getCell('B4').value = '적립 건수'
      worksheet.getCell('C4').value = '적립 회원수'
      worksheet.getCell('D4').value = '적립 금액'
      worksheet.getCell('E4').value = '적립 건수'
      worksheet.getCell('F4').value = '적립 회원수'
      worksheet.getCell('G4').value = '적립 금액'
      worksheet.getCell('H4').value = '적립 건수'
      worksheet.getCell('I4').value = '적립 비회원수'
      worksheet.getCell('J4').value = '적립 금액'
      worksheet.getCell('K4').value = '적립 건수'
      worksheet.getCell('L4').value = '적립 회원수'
      worksheet.getCell('M4').value = '적립 금액'
      worksheet.getCell('N4').value = '적립 건수'
      worksheet.getCell('O4').value = '적립 회원수'
      worksheet.getCell('P4').value = '적립 금액'
      worksheet.getCell('Q4').value = '적립 건수'
      worksheet.getCell('R4').value = '적립 비회원수'
      worksheet.getCell('S4').value = '적립 금액'
      worksheet.getCell('T4').value = '적립 건수'
      worksheet.getCell('U4').value = '적립 회원수'
      worksheet.getCell('V4').value = '적립 금액'
      worksheet.getCell('W4').value = '적립 건수'
      worksheet.getCell('X4').value = '적립 회원수'
      worksheet.getCell('Y4').value = '적립 금액'
      worksheet.getCell('Z4').value = '적립 건수'
      worksheet.getCell('AA4').value = '적립 비회원수'
      worksheet.getCell('AB4').value = '적립 금액'
      worksheet.getCell('AC4').value = '적립 건수'
      worksheet.getCell('AD4').value = '적립 회원수'
      worksheet.getCell('AE4').value = '적립 금액'
      worksheet.getCell('AF4').value = '적립 건수'
      worksheet.getCell('AG4').value = '적립 회원수'
      worksheet.getCell('AH4').value = '적립 금액'
      worksheet.getCell('AI4').value = '적립 건수'
      worksheet.getCell('AJ4').value = '적립 비회원수'
      worksheet.getCell('AK4').value = '적립 금액'
      worksheet.getCell('AL4').value = '적립 건수'
      worksheet.getCell('AM4').value = '적립 회원수'
      worksheet.getCell('AN4').value = '적립 금액'
      worksheet.getCell('AO4').value = '적립 건수'
      worksheet.getCell('AP4').value = '적립 회원수'
      worksheet.getCell('AQ4').value = '적립 금액'
      worksheet.getCell('AR4').value = '적립 건수'
      worksheet.getCell('AS4').value = '적립 회원수'
      worksheet.getCell('AT4').value = '적립 금액'
      worksheet.getCell('AU4').value = '적립 건수'
      worksheet.getCell('AV4').value = '적립 회원수'
      worksheet.getCell('AW4').value = '적립 금액'
      worksheet.getCell('AX4').value = '적립 건수'
      worksheet.getCell('AY4').value = '적립 회원수'
      worksheet.getCell('AZ4').value = '적립 금액'
      worksheet.getCell('BA4').value = '적립 건수'
      worksheet.getCell('BB4').value = '적립 비회원수'
      worksheet.getCell('BC4').value = '적립 금액'

      const columns = [
        { key: "date", width: 20 },
        { key: "selfSurveyPointCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "selfSurveyPointGroup", width: 15, style: { numFmt: '#,##0' } },
        { key: "selfSurveyPointAmount", width: 15, style: { numFmt: '#,##0' } },
        { key: "selfSurveyGiftCountMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "selfSurveyGiftGroupMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "selfSurveyGiftAmountMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "selfSurveyGiftCountNonMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "selfSurveyGiftGroupNonMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "selfSurveyGiftAmountNonMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "selfMcmSurveyPointCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "selfMcmSurveyPointGroup", width: 15, style: { numFmt: '#,##0' } },
        { key: "selfMcmSurveyPointAmount", width: 15, style: { numFmt: '#,##0' } },
        { key: "selfMcmSurveyGiftCountMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "selfMcmSurveyGiftGroupMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "selfMcmSurveyGiftAmountMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "selfMcmSurveyGiftCountNonMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "selfMcmSurveyGiftGroupNonMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "selfMcmSurveyGiftAmountNonMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "clientSurveyPointCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "clientSurveyPointGroup", width: 15, style: { numFmt: '#,##0' } },
        { key: "clientSurveyPointAmount", width: 15, style: { numFmt: '#,##0' } },
        { key: "clientSurveyGiftCountMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "clientSurveyGiftGroupMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "clientSurveyGiftAmountMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "clientSurveyGiftCountNonMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "clientSurveyGiftGroupNonMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "clientSurveyGiftAmountNonMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "eventPointCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "eventPointGroup", width: 15, style: { numFmt: '#,##0' } },
        { key: "eventPointAmount", width: 15, style: { numFmt: '#,##0' } },
        { key: "eventGiftCountMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "eventGiftGroupMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "eventGiftAmountMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "eventGiftCountNonMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "eventGiftGroupNonMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "eventGiftAmountNonMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "medicalTrendCommentTotalCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "medicalTrendCommentGroupCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "medicalTrendCommentTotalPoint", width: 15, style: { numFmt: '#,##0' } },
        { key: "mySeminarTotalCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "mySeminarGroupCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "mySeminarTotalPoint", width: 15, style: { numFmt: '#,##0' } },
        { key: "weeklyChoiceTotalCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "weeklyChoiceGroupCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "weeklyChoiceTotalPoint", width: 15, style: { numFmt: '#,##0' } },
        { key: "allPointCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "allPointGroup", width: 15, style: { numFmt: '#,##0' } },
        { key: "allPointAmount", width: 15, style: { numFmt: '#,##0' } },
        { key: "allGiftCountMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "allGiftGroupMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "allGiftAmountMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "allGiftCountNonMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "allGiftGroupNonMember", width: 15, style: { numFmt: '#,##0' } },
        { key: "allGiftAmountNonMember", width: 15, style: { numFmt: '#,##0' } },
      ]

      worksheet.columns = columns

      // eslint-disable-next-line array-callback-return
      data.value.map(e => {
        const record = {
          date: (data.value.length === 1 && e.isPrefixSum) ? '누적 수' : (e.isPrefixSum ? "합계" : e.date),
          selfSurveyPointCount: e.selfSurveyPointCount,
          selfSurveyPointGroup: e.selfSurveyPointGroup,
          selfSurveyPointAmount: e.selfSurveyPointAmount,
          selfSurveyGiftCountMember: e.selfSurveyGiftCountMember,
          selfSurveyGiftGroupMember: e.selfSurveyGiftGroupMember,
          selfSurveyGiftAmountMember: e.selfSurveyGiftAmountMember,
          selfSurveyGiftCountNonMember: e.selfSurveyGiftCountNonMember,
          selfSurveyGiftGroupNonMember: e.selfSurveyGiftGroupNonMember,
          selfSurveyGiftAmountNonMember: e.selfSurveyGiftAmountNonMember,
          selfMcmSurveyPointCount: e.selfMcmSurveyPointCount,
          selfMcmSurveyPointGroup: e.selfMcmSurveyPointGroup,
          selfMcmSurveyPointAmount: e.selfMcmSurveyPointAmount,
          selfMcmSurveyGiftCountMember: e.selfMcmSurveyGiftCountMember,
          selfMcmSurveyGiftGroupMember: e.selfMcmSurveyGiftGroupMember,
          selfMcmSurveyGiftAmountMember: e.selfMcmSurveyGiftAmountMember,
          selfMcmSurveyGiftCountNonMember: e.selfMcmSurveyGiftCountNonMember,
          selfMcmSurveyGiftGroupNonMember: e.selfMcmSurveyGiftGroupNonMember,
          selfMcmSurveyGiftAmountNonMember: e.selfMcmSurveyGiftAmountNonMember,
          clientSurveyPointCount: e.clientSurveyPointCount,
          clientSurveyPointGroup: e.clientSurveyPointGroup,
          clientSurveyPointAmount: e.clientSurveyPointAmount,
          clientSurveyGiftCountMember: e.clientSurveyGiftCountMember,
          clientSurveyGiftGroupMember: e.clientSurveyGiftGroupMember,
          clientSurveyGiftAmountMember: e.clientSurveyGiftAmountMember,
          clientSurveyGiftCountNonMember: e.clientSurveyGiftCountNonMember,
          clientSurveyGiftGroupNonMember: e.clientSurveyGiftGroupNonMember,
          clientSurveyGiftAmountNonMember: e.clientSurveyGiftAmountNonMember,
          eventPointCount: e.eventPointCount,
          eventPointGroup: e.eventPointGroup,
          eventPointAmount: e.eventPointAmount,
          eventGiftCountMember: e.eventGiftCountMember,
          eventGiftGroupMember: e.eventGiftGroupMember,
          eventGiftAmountMember: e.eventGiftAmountMember,
          eventGiftCountNonMember: e.eventGiftCountNonMember,
          eventGiftGroupNonMember: e.eventGiftGroupNonMember,
          eventGiftAmountNonMember: e.eventGiftAmountNonMember,
          medicalTrendCommentTotalCount: e.medicalTrendCommentTotalCount,
          medicalTrendCommentGroupCount: e.medicalTrendCommentGroupCount,
          medicalTrendCommentTotalPoint: e.medicalTrendCommentTotalPoint,
          mySeminarTotalCount: e.mySeminarTotalCount,
          mySeminarGroupCount: e.mySeminarGroupCount,
          mySeminarTotalPoint: e.mySeminarTotalPoint,
          weeklyChoiceTotalCount: e.weeklyChoiceTotalCount,
          weeklyChoiceGroupCount: e.weeklyChoiceGroupCount,
          weeklyChoiceTotalPoint: e.weeklyChoiceTotalPoint,
          allPointCount: e.allPointCount,
          allPointGroup: e.allPointGroup,
          allPointAmount: e.allPointAmount,
          allGiftCountMember: e.allGiftCountMember,
          allGiftGroupMember: e.allGiftGroupMember,
          allGiftAmountMember: e.allGiftAmountMember,
          allGiftCountNonMember: e.allGiftCountNonMember,
          allGiftGroupNonMember: e.allGiftGroupNonMember,
          allGiftAmountNonMember: e.allGiftAmountNonMember,
        }

        worksheet.addRow(record)
      })

      worksheet.eachRow({ includeEmpty: true }, (row, number) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (number < 5) {
            // eslint-disable-next-line no-param-reassign
            cell.font = {
              bold: true,
            }

            // eslint-disable-next-line no-param-reassign
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            }
          }

          // eslint-disable-next-line no-param-reassign
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }

          if (cell.value === '소계' || cell.value === '합계') {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
            }
          } else {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
            }
          }
        })
      })

      const mimeType = {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }

      const buffer = await workbook.xlsx.writeBuffer()
      const blob = new Blob([buffer], mimeType)

      saveAs(blob, "포인트적립현황_상세.xlsx")
    }

    return {
      data,
      query,
      viewType,
      fetchData,
      changePrefixSum,
      excelDownload,
    }
  },
}
</script>

<style scoped>
.table-bordered th, td {
  border: 1px solid #dee2e6;
}

.table th {
  vertical-align: middle;
}
</style>
