<template>
  <div>
    <b-card>
      <b-tabs pills>
        <b-tab
          title="포인트 사용/만료"
          @click="viewType = 'summary'"
        />
        <b-tab
          title="포인트 적립 현황"
          @click="viewType = 'earning'"
        />
        <b-tab
          title="포인트 최대 적립금액"
          @click="viewType = 'earning-max'"
        />
        <b-tab
          title="포인트 교환 현황"
          @click="viewType = 'exchange'"
        />
      </b-tabs>
    </b-card>

    <b-card>
      <summary-view v-if="viewType === 'summary'" />
      <earning-view v-else-if="viewType === 'earning'" />
      <earning-max-view v-else-if="viewType === 'earning-max'" />
      <exchange-view v-else-if="viewType === 'exchange'" />
    </b-card>
  </div>
</template>

<script>
import { BTab, BTabs } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import SummaryView from './view/SummaryView.vue'
import EarningView from './view/EarningView.vue'
import EarningMaxView from './view/EarningMaxView.vue'
import ExchangeView from './view/ExchangeView.vue'

export default {
  components: {
    BTab,
    BTabs,
    SummaryView,
    EarningView,
    EarningMaxView,
    ExchangeView,
  },

  setup() {
    const viewType = ref("summary")

    return {
      viewType,
    }
  },
}
</script>
