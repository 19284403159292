import _ from 'lodash'

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

export const incrementAlphabet = input => {
  // 정규식을 사용하여 문자열에서 알파벳 부분과 숫자 부분을 분리합니다.
  const regex = /([A-Z]+)/
  const matches = input.match(regex)

  if (!matches) {
    throw new Error('올바른 형식의 입력이 아닙니다.')
  }

  const alphabetPart = matches[1] // 알파벳 부분

  // 알파벳 부분을 배열로 분리합니다.
  const alphabetArray = alphabetPart.split('')

  // 알파벳 배열을 숫자로 변환하고 1을 더합니다.
  for (let i = alphabetArray.length - 1; i >= 0; i -= 1) {
    const charCode = alphabetArray[i].charCodeAt(0)
    if (charCode === 90) { // 'Z'의 경우
      alphabetArray[i] = 'A'
      if (i === 0) {
        // 가장 왼쪽 알파벳이 'Z'인 경우 새로운 알파벳을 추가합니다.
        alphabetArray.unshift('A')
      }
    } else {
      alphabetArray[i] = String.fromCharCode(charCode + 1)
      break
    }
  }

  return alphabetArray.join('')
}

const getRandomFromArray = array => array[Math.floor(Math.random() * array.length)]

export const getRandomBsVariant = () => getRandomFromArray(['primary', 'secondary', 'success', 'warning', 'danger', 'info'])

export const clone = target => JSON.parse(JSON.stringify(target))

export const isEqual = (a, b) => _.isEqual(a, b)
